.hosted-field {
    height: 50px;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    display: inline-block;
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #dddddd;
    line-height: 20px;
    background: #fcfcfc;
    margin-bottom: 12px;
    background: linear-gradient(to right, white 50%, #fcfcfc 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 300ms ease-in-out;
}
  

  
.button-container {
    display: block;
    text-align: center;
}
  
.button {
    cursor: pointer;
    font-weight: 500;
    line-height: inherit;
    position: relative;
    text-decoration: none;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
}
  
.button--small {
    padding: 10px 20px;
    font-size: 0.875rem;
}
  
.button--green {
    outline: none;
    background-color: #64d18a;
    border-color: #64d18a;
    color: white;
    transition: all 200ms ease;
}
  
.button--green:hover {
    background-color: #8bdda8;
    color: white;
}

.hosted-field:focused {
    border: 1px solid #64d18a;
    border-radius: 6px;
    background-position: left bottom;
}
  
.hosted-field.is-invalid {
    border-radius: 6px;
    border: 1px solid #ed574a;
}
  
.hosted-field.is-valid {
    border-radius: 6px;
    border: 1px solid #64d18a;
}
  
#cardForm {
    max-width: 50.75em;
    margin: 0 auto;
    padding: 1.875em;
}